<script setup
        lang="ts">
import { ref }             from 'vue';
import { type MenuOption } from './menu.types';
import MenuLogo            from './logo/menu-logo.vue';
import MenuOptions         from './options/menu-options.vue';
import MenuMobile          from './mobile/menu-mobile.vue';

withDefaults(defineProps<{
    isLoggedIn: boolean;
}>(), {
    isLoggedIn: false,
});

const options: MenuOption[] = [
    {
        url:   '/features',
        label: 'features',
    },
    {
        url:   '/blog',
        label: 'blog',
    },
    {
        url:   '/pricing',
        label: 'pricing',
    },
    {
        url:      import.meta.env.PUBLIC_DOCS_URL,
        label:    'documentation',
        external: true,
    },
];

const active = ref<boolean>(false);
</script>

<template>
    <div class="menu--wrapper">
        <div class="menu--inner">
            <MenuLogo :active="active" />
            <MenuOptions :options="options"
                         :active="active"
                         :logged-in="isLoggedIn"
                         @toggle="active = !active" />
        </div>
        <MenuMobile :options="options"
                    :active="active"
                    :logged-in="isLoggedIn" />
    </div>
</template>

<style lang="scss"
       scoped>
.menu--wrapper {
    position:        absolute;
    z-index:         9999;
    display:         flex;
    justify-content: center;
    width:           100%;

    .menu--inner {
        top:             0;
        left:            0;
        display:         flex;
        align-items:     center;
        justify-content: space-between;
        width:           100%;
        max-width:       75rem;
        height:          var(--reshepe-menu-height);
        margin-top:      16px;
        margin-bottom:   16px;
        padding:         0 16px;
    }
}
</style>
