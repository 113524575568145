<script setup
        lang="ts">
import { type MenuOption } from '../menu.types';
import MenuBurger          from '../burger/menu-burger.vue';
import ButtonSimple        from '../../button/simple/button-simple.vue';

defineProps<{
    active: boolean;
    loggedIn: boolean;
    options: MenuOption[];
}>();

const emit = defineEmits<{
    (e: 'toggle'): void;
}>();

const redirect = (url: string): void => {
    window.location.href = `${import.meta.env.PUBLIC_DASHBOARD_URL}${url}`;
};
</script>

<template>
    <div class="menu-options--wrapper">
        <a v-for="option in options"
           :key="option.url"
           :href="option.url"
           :target="option.external ? '_blank' : '_self'"
           class="menu-options--option desktop">
            {{ option.label }}
        </a>
        <ButtonSimple v-if="!loggedIn"
                      class="menu-options--button desktop"
                      text="sign in"
                      arrow
                      @click="redirect('/auth/login')" />
        <ButtonSimple v-if="loggedIn"
                      class="menu-options--button desktop"
                      text="dashboard"
                      arrow
                      @click="redirect('/')" />
        <MenuBurger :active="active"
                    @toggle="emit('toggle')" />
    </div>
</template>

<style lang="scss"
       scoped>
.menu-options--wrapper {
    --menu-options-option-color:       var(--reshepe-color-white-700);
    --menu-options-option-hover-color: var(--reshepe-color-white-500);

    position:                          relative;
    display:                           flex;
    align-items:                       center;

    .desktop {
        display: none;

        @include tablet {
            display: inline-flex;
        }
    }

    .menu-options--button {
        margin-left: 4em;
    }

    .menu-options--option {
        padding:         0 1em;
        color:           var(--menu-options-option-color);
        font-size:       1rem;
        text-decoration: none;
        cursor:          pointer;
        transition:      all 0.2s ease-in-out;

        &:hover {
            color: var(--menu-options-option-hover-color);
        }
    }
}

@include light-theme {
    .menu-options--wrapper {
        --menu-options-option-color:       var(--reshepe-color-text-600);
        --menu-options-option-hover-color: var(--reshepe-color-text-500);
    }
}
</style>
