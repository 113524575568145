<script setup
        lang="ts">
defineProps<{
    active: boolean;
}>();

const emit = defineEmits<{
    (e: 'toggle'): void;
}>();
</script>

<template>
    <div class="menu-burger"
         :class="{
             active,
         }"
         @click="emit('toggle')">
        <div class="menu-burger--line line-1" />
        <div class="menu-burger--line line-2" />
        <div class="menu-burger--line line-3" />
    </div>
</template>

<style lang="scss"
       scoped>
.menu-burger {
    --menu-burger-color:         var(--reshepe-color-white-700);
    --menu-burger-hover-color:   var(--reshepe-color-white-500);
    --menu-burger-active-color:  var(--reshepe-color-white-500);

    position:                    relative;
    z-index:                     10;
    display:                     flex;
    align-items:                 center;
    width:                       1.75rem;
    height:                      var(--reshepe-menu-height);
    cursor:                      pointer;
    user-select:                 none;
    -webkit-tap-highlight-color: transparent;

    @include tablet {
        display: none;
    }

    .menu-burger--line {
        position:         absolute;
        left:             0;
        width:            1.75rem;
        height:           0.125rem;
        background-color: var(--menu-burger-color);
        user-select:      none;
        transition:       all 0.5s ease-in-out;

        &.line-1 {
            top: 30%;
        }

        &.line-2 {
            top:   50%;
            right: 0;
        }

        &.line-3 {
            top:     70%;
            opacity: 1;
        }
    }

    &:hover {
        .menu-burger--line {
            background-color: var(--menu-burger-hover-color);
        }
    }

    &.active {
        .menu-burger--line {
            background-color: var(--menu-burger-active-color);

            &.line-1 {
                transform: rotate(405deg) translateY(7px) translateX(5.5px) scale(0.7);
            }

            &.line-2 {
                transform: rotate(-405deg) translateY(-1px) translateX(-1px) scale(0.7);
            }

            &.line-3 {
                opacity: 0;
            }
        }
    }
}

@include light-theme {
    .menu-burger {
        --menu-burger-color:       var(--reshepe-color-text-700);
        --menu-burger-hover-color: var(--reshepe-color-text-500);
    }
}
</style>
