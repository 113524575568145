<script setup
        lang="ts">
import { type MenuOption } from '../menu.types';
import ButtonSimple        from '../../button/simple/button-simple.vue';

defineProps<{
    active: boolean;
    loggedIn: boolean;
    options: MenuOption[];
}>();

const redirect = (url: string): void => {
    window.location.href = `${import.meta.env.PUBLIC_DASHBOARD_URL}${url}`;
};
</script>

<template>
    <Transition>
        <div v-if="active"
             class="menu-mobile--wrapper">
            <div class="menu-mobile--options">
                <a v-for="option in options"
                   :key="option.url"
                   :href="option.url"
                   class="menu-mobile--option">
                    {{ option.label }}
                </a>
            </div>
            <div class="menu-mobile--buttons">
                <ButtonSimple v-if="!loggedIn"
                              class="menu-mobile--button desktop"
                              text="sign in"
                              arrow
                              @click="redirect('/auth/login')" />
                <ButtonSimple v-if="loggedIn"
                              class="menu-mobile--button desktop"
                              text="dashboard"
                              arrow
                              @click="redirect('/')" />
            </div>
        </div>
    </Transition>
</template>

<style lang="scss"
       scoped>
.menu-mobile--wrapper {
    --menu-mobile-bg:                 var(--reshepe-color-black-alt-500);
    --menu-mobile-color:              var(--reshepe-color-white-700);
    --menu-mobile-option-color:       var(--reshepe-color-white-700);
    --menu-mobile-option-hover-color: var(--reshepe-color-white-500);

    position:                         absolute;
    top:                              0;
    z-index:                          9;
    display:                          flex;
    flex-flow:                        column;
    justify-content:                  space-between;
    width:                            100%;
    min-height:                       400px;
    padding:                          6em 1em 1em;
    border-radius:                    0 0 4px 4px;
    background-color:                 var(--menu-mobile-bg);
    color:                            var(--menu-mobile-color);
    box-shadow:                       rgb(0 0 0 / 25%) 0 24px 25px, rgb(0 0 0 / 12%) 0 -12px 30px, rgb(0 0 0 / 12%) 0 4px 6px, rgb(0 0 0 / 17%) 0 12px 13px, rgb(0 0 0 / 9%) 0 -3px 5px;

    @include tablet {
        display: none;
    }

    &.v-enter-active,
    &.v-leave-active {
        transition: all 0.5s ease;

        .menu-mobile--options {
            opacity:          1;
            transition:       all 0.2s ease-in-out;
            transition-delay: 0.3s;
        }

        .menu-mobile--buttons {
            opacity:          1;
            transition:       all 0.2s ease-in-out;
            transition-delay: 0.3s;
        }
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(calc(-100% - 1em));

        .menu-mobile--options {
            opacity:          0;
            transition:       all 0s ease-in-out;
            transition-delay: 0s;
        }

        .menu-mobile--buttons {
            opacity:          0;
            transition:       all 0s ease-in-out;
            transition-delay: 0s;
        }
    }

    .menu-mobile--options {
        display:   flex;
        flex-flow: column nowrap;

        .menu-mobile--option {
            margin:          0.5em 0;
            color:           var(--menu-mobile-option-color);
            font-size:       1em;
            text-decoration: none;
            transition:      all 0.2s ease-in-out;

            &:hover {
                color: var(--menu-mobile-option-hover-color);
            }
        }
    }

    .menu-mobile--buttons {
        display:         flex;
        justify-content: flex-start;

        .menu-mobile--button {
            max-width: 120px;
        }
    }
}
</style>
