<script setup
        lang="ts">
defineProps<{
    active: boolean;
}>();
</script>

<template>
    <div class="menu--logo"
         :class="{
             active,
         }">
        <a class="logo--link"
           href="/">
            <span class="logo--inner">reshepe<span class="beta-tag">[beta]</span></span>
            <span class="logo--inner mobile">r:<span class="beta-tag">[beta]</span></span>
        </a>
    </div>
</template>

<style lang="scss"
       scoped>
.menu--logo {
    --menu-logo-color:              var(--reshepe-color-text-500);
    --menu-logo-hover-color:        var(--reshepe-color-text-600);
    --menu-logo-active-color:       var(--reshepe-color-text-500);
    --menu-logo-active-hover-color: var(--reshepe-color-text-600);
    --menu-logo-beta-color:         var(--reshepe-color-primary-500);

    z-index:                        9999;
    width:                          25%;

    .logo--link {
        font-size:       2.4rem;
        font-family:     var(--reshepe-font-alt-thin);
        line-height:     1;
        text-decoration: none;
        cursor:          pointer;
        user-select:     none;
        transition:      color 0.2s ease-in-out;

        .logo--inner {
            display:    none;
            color:      var(--menu-logo-color);
            cursor:     pointer;
            transition: color 0.2s ease-in-out;

            @include tablet {
                display: flex;
            }

            .beta-tag {
                margin-left: 0.4rem;
                color:       var(--menu-logo-beta-color);
                font-size:   0.85rem;
            }

            &.mobile {
                display:                     flex;
                color:                       var(--menu-logo-color);
                transition:                  color 0.2s ease-in-out;
                -webkit-tap-highlight-color: transparent;

                @include tablet {
                    display: none;
                }
            }
        }

        &:hover {
            .logo--inner {
                color: var(--menu-logo-hover-color);

                &.mobile {
                    color: var(--menu-logo-hover-color);
                }
            }
        }
    }

    &.active {
        .logo--link {
            .logo--inner {
                &.mobile {
                    color: var(--menu-logo-active-color);
                }
            }

            &:hover {
                .logo--inner {
                    &.mobile {
                        color: var(--menu-logo-active-hover-color);
                    }
                }
            }
        }
    }
}

@include light-theme {
    .menu--logo {
        --menu-logo-active-color:       var(--reshepe-color-white-500);
        --menu-logo-active-hover-color: var(--reshepe-color-white-600);
        --menu-logo-beta-color:         var(--reshepe-color-primary-900);
    }
}
</style>
